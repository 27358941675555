import { useState, useEffect } from "react";
import useAxiosPrivate from "./Hooks/useAxiosPrivate";
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SinUser from "./SinUser";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";



const Users = () => {
    const [users, setUsers] = useState();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);
    // const navigate = useNavigate();
    // const location = useLocation();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                setLoading(true);
                const response = await axiosPrivate.get('/api/users-list', {
                    signal: controller.signal
                });
                // console.log(response.data.data);
                isMounted && setUsers(response.data.data);
                setLoading(false);
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        }

        getUsers();

        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [])

    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10;

    useEffect(() => {
        setTotalPages(Math.ceil(users?.length / itemsPerPage));
    }, []);

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const subset = users?.slice(startIndex, endIndex);
    console.log(subset?.length);


    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    return (

        <>
            <SinUser />
            <h5 className='px-4 mt-6'><span className='inline-block place-content-center align-top text-blue-800 text-xl ml-2'><FontAwesomeIcon icon={faUserGroup} /></span>سایر کاربران</h5>
            <div className="news-card grid grid-cols-1 md:grid-cols-2 gap-x-4 mt-2 rounded-md p-4">
                {loading ? <div className='flex'>< p className=' text-right text-lg mt-2 pr-6' > در حال بارگذاری...</p></div>
                    : subset?.map((log, i) => (
                        <div key={i} className="w-full bg-gray-50 hover:bg-blue-200 cursor-pointer select-none transition-all duration-200 ease-in border border-slate-400 rounded-md mb-3 p-3">
                            <div className="news-card-header mt-3 col-span-3">
                                <div className='flex md:flex-col lg:flex-row justify-between'>
                                    <div className='flex items-center mr-6 w-2/3'>
                                        {/* <img src={contactImage} className="rounded-md ml-2 pb-2" width={40} height={40} /> */}
                                        <div className='flex flex-col justify-between'>
                                            <h6 className="m-0 ">نام کاربری: {log?.Username}
                                            </h6>
                                            <p className="m-0 text-sm cursor-pointer mt-1 text-gray-600">
                                                شناسه کاربری: {log?.id}
                                            </p>
                                            <p className="m-0 text-sm cursor-pointer mt-1 text-gray-600">
                                                مدل دستگاه: {log?.Model}
                                            </p>
                                        </div>
                                    </div>
                                    <div className=' w-1/3 flex flex-col items-center justify-center'>
                                        <p>{log?.status ? <span className="text-white text-sm bg-green-700 px-1 rounded">فعال</span> : <span className=" text-white text-sm bg-red-700 px-1 rounded">غیرفعال</span>}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>

            {
                !loading ? <div className='pr-6'>
                    {subset?.length > 10 ? <ReactPaginate
                        className='pagination-container flex items-center p-0 m-0 rounded-md w-fit'
                        pageCount={totalPages}
                        onPageChange={handlePageChange}
                        forcePage={currentPage}
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={"..."}
                        containerClassName={"pagination-container"}
                        activeClassName={"active-page"}
                    />
                        : ''
                    }
                </div>
                    : ''
            }
        </>

    )
}

export default Users
