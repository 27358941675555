import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SideMenu from "./SideMenu";
import Header from "./Header";
import useAxiosPrivate from "./Hooks/useAxiosPrivate";
import ReactPaginate from 'react-paginate';
import { faClipboardCheck, faClipboardList, faUser } from '@fortawesome/free-solid-svg-icons';


const ActionList = () => {

    const [action, setAction] = useState();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState(13);
    // const navigate = useNavigate();
    // const location = useLocation();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getActions = async () => {
            try {
                setLoading(true);
                const response = await axiosPrivate.get(`/api/job-list?page=1&userId=${userId}`, {
                    signal: controller.signal
                });
                // console.log(response.data.data);
                isMounted && setAction(response.data.data);
                console.error(response.data.data);
                setLoading(false);
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        }

        getActions();
        console.error(action);
        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [])

    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10;

    useEffect(() => {
        setTotalPages(Math.ceil(action?.length / itemsPerPage));
    }, []);

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const subset = action?.slice(startIndex, endIndex);
    console.log(action?.length);


    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    return (

        <>
            <Header />
            <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 overflow-y-auto'>
                <div className="md:col-span-7 bg-white md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
                <span className='inline-block  place-content-center mr-4 text-blue-800 text-lg ml-2'><FontAwesomeIcon icon={faClipboardList} /></span>
                <h5 className="inline-flex items-center">فعالیت‌های اخیر</h5>
                <p className=' px-8'>
            لیست فعالیت‌های اخیر در این بخش قابل مشاهده است.
          </p>
                        <div className="news-card grid grid-cols-1 gap-x-4 mt-2 rounded-md p-4">
                            {loading ? <div className='flex'>< p className=' text-right text-lg mt-2 pr-6' > در حال بارگذاری...</p></div>
                                : subset?.map((log, i) => (
                                    <div key={i} className="w-full bg-gray-50 hover:bg-blue-200 cursor-pointer select-none transition-all duration-200 ease-in border border-slate-400 rounded-md mb-3 p-3">
                                        <div className="news-card-header col-span-3">
                                            <div className='flex md:flex-col lg:flex-row justify-between'>
                                                <div className='flex items-center mr-6 w-2/3'>
                                                    <div className='flex flex-col justify-between'>
                                                        <p className="m-0 text-sm cursor-pointer mt-1 text-gray-600">
                                                            نوع فعالیت: {log?.content}
                                                        </p>
                                                        { log?.duration ? <p className="m-0 text-sm cursor-pointer text-gray-600">
                                                            مدت فعالیت: {log?.duration}
                                                        </p> : ''}
                                                        <p className="m-0 text-sm cursor-pointer text-gray-600">
                                                            کد فعالیت: {log?.id}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className=' w-1/3 flex flex-col items-center justify-center'>
                                                    <p className="m-0 text-sm text-right cursor-pointer mt-1 text-gray-600">
                                                        تاریخ: {log?.date}
                                                    </p>
                                                    <p className="m-0 text-sm text-right cursor-pointer mt-1 text-gray-600">
                                                        ساعت: {log?.time?.slice(0, 8)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                </div>
            </div>


            {
                !loading ? <div className='pr-6'>
                    {action?.length > 10 ? <ReactPaginate
                        className='pagination-container flex items-center p-0 m-0 rounded-md w-fit'
                        pageCount={totalPages}
                        onPageChange={handlePageChange}
                        forcePage={currentPage}
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={"..."}
                        containerClassName={"pagination-container"}
                        activeClassName={"active-page"}
                    />
                        : ''
                    }
                </div>
                    : ''
            }
        </>
    )
}

export default ActionList