// Single number input component
import { useContext, useState, useRef } from "react";
import AuthContext from './Context/AuthProvider';
import * as XLSX from 'xlsx';

function BulkInp() {

    const {tableShow, setTableShow, setShow} = useContext(AuthContext);
    const inputRef = useRef(null);

        // onchange states
        const [excelFile, setExcelFile] = useState(null);
        const [typeError, setTypeError] = useState(null);
    
        //submit state
        const [excelData, setExcelData] = useState(null);
    
        //onchange event
        const handleFile = (e) => {
            let fileTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
            let selectedFile = e.target.files[0];
            console.log(e.target.files[0]);
            if (selectedFile) {
                if (selectedFile && fileTypes.includes(selectedFile.type)) {
                    setTypeError(null);
                    let reader = new FileReader();
                    reader.readAsArrayBuffer(selectedFile);
                    reader.onload = (e) => {
                        setExcelFile(e.target.result);
                    }
                } else {
                    setTypeError('لطفا فقط فایل اکسل وارد کنید.');
                    setExcelFile(null);
                }
            } else {
                console.log('Please select');
            }
        }
    
        //submit event
        const handleFileSubmit = (e) => {
            e.preventDefault();
            if (excelFile !== null) {
                const workbook = XLSX.read(excelFile, { typeError: 'buffer' });
                const worksheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[worksheetName];
                const data = XLSX.utils.sheet_to_json(worksheet);
                setExcelData(data);
                setTableShow(false);
                setShow(false);
                inputRef.current.value = "";
            }
        }

    return (
        <div className="lg:w-1/2 mx-auto mt-3">
            <div className="mx-auto text-center">
                <form onSubmit={handleFileSubmit}>
                    <label className="ml-2">ورود مشخصه انبوه:</label>
                    <input required type="file" ref={inputRef} onChange={handleFile} className=" w-1/2 h-10 py-2 px-3 mx-auto rounded bg-white transition-all duration-200 ease-in mt-1 focus:shadow-slate-500 focus:shadow-md outline-2 outline-middle-yellow" />
                    <button type="submit" className="py-2 px-4 h-10 subBut text-white hover:bg-yellow bg-light-green rounded mr-2 mt-2 cursor-pointer">ثبت</button>
                    {typeError && (
                        <div className="alert" role="alert">{typeError}</div>
                    )}
                </form>
            </div>
        </div>
    )

}

export default BulkInp