import { useContext, useState, useEffect } from "react";
import ReactPaginate from 'react-paginate';
import AuthContext from './Context/AuthProvider';
import CallLog from '../JsonFiles/CallLog.json';
import { faCheckSquare, faXmarkSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTelegram, faTwitter, faWhatsapp } from "@fortawesome/free-brands-svg-icons";


function InqTable() {

    const { setDetShow, show, tableShow, setSocailCh, setContactImg, numInp } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    //Pagination
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10;

    useEffect(() => {
        setTotalPages(Math.ceil(CallLog.length / itemsPerPage));
    }, []);

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const subset = CallLog.slice(startIndex, endIndex);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };
    return (
        <>
            <div className=" text-center text-2xl">
                <div className="table-responsive mt-6 p-8">
                    <table className="table border-2">
                        <thead className=" bg-middle-green">
                            <tr>
                                <th className="text-lg bg-middle-green w-40">
                                    شماره
                                </th>
                                <th className="text-xl">
                                    <FontAwesomeIcon icon={faTelegram} />
                                </th>
                                <th className="text-xl">
                                    <FontAwesomeIcon icon={faInstagram} />
                                </th>
                                <th className="text-xl">
                                    <FontAwesomeIcon icon={faWhatsapp} />
                                </th>
                                <th className="text-xl">
                                    <FontAwesomeIcon icon={faTwitter} />
                                </th>
                                <th className="text-xl">
                                    <FontAwesomeIcon icon={faFacebook} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {subset?.map((item, i) => (
                                <tr key={i}>
                                    <td className="text-lg">{item?.number}</td>
                                    <td>{item?.telegram
                                        ? <div className="flex justify-center items-center"><span className='inline-block place-content-center align-top text-green-500 text-lg'><FontAwesomeIcon icon={faCheckSquare} /></span></div>
                                        : <div className="flex justify-center items-center"><span className='inline-block place-content-center align-top text-red-500 text-lg'><FontAwesomeIcon icon={faXmarkSquare} /></span></div>}
                                    </td>
                                    <td>{item?.instagram
                                        ? <div className="flex justify-center items-center"><span className='inline-block place-content-center align-top text-green-500 text-lg'><FontAwesomeIcon icon={faCheckSquare} /></span></div>
                                        : <div className="flex justify-center items-center"><span className='inline-block place-content-center align-top text-red-500 text-lg'><FontAwesomeIcon icon={faXmarkSquare} /></span></div>}
                                    </td>
                                    <td>{item?.whatsapp
                                        ? <div className="flex justify-center items-center"><span className='inline-block place-content-center align-top text-green-500 text-lg'><FontAwesomeIcon icon={faCheckSquare} /></span></div>
                                        : <div className="flex justify-center items-center"><span className='inline-block place-content-center align-top text-red-500 text-lg'><FontAwesomeIcon icon={faXmarkSquare} /></span></div>}
                                    </td>
                                    <td>{item?.twitter
                                        ? <div className="flex justify-center items-center"><span className='inline-block place-content-center align-top text-green-500 text-lg'><FontAwesomeIcon icon={faCheckSquare} /></span></div>
                                        : <div className="flex justify-center items-center"><span className='inline-block place-content-center align-top text-red-500 text-lg'><FontAwesomeIcon icon={faXmarkSquare} /></span></div>}
                                    </td>
                                    <td>{item?.facebook
                                        ? <div className="flex justify-center items-center"><span className='inline-block place-content-center align-top text-green-500 text-lg'><FontAwesomeIcon icon={faCheckSquare} /></span></div>
                                        : <div className="flex justify-center items-center"><span className='inline-block place-content-center align-top text-red-500 text-lg'><FontAwesomeIcon icon={faXmarkSquare} /></span></div>}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className='pr-6'>
                        <ReactPaginate
                            className='pagination-container flex items-center p-0 m-0 rounded-md w-fit'
                            pageCount={totalPages}
                            onPageChange={handlePageChange}
                            forcePage={currentPage}
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            breakLabel={"..."}
                            containerClassName={"pagination-container"}
                            activeClassName={"active-page"}
                        />
                    </div>
                </div>

            </div>
        </>
    )

}

export default InqTable
