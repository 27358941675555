import React, { Component } from 'react';
import Lottie from 'react-lottie';
import animationData from '../lottie/Animation - 1717616036064.json';

class SigninLottie extends Component {
    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
            },
        };

        return (
            <Lottie
                options={defaultOptions}
                height={300}
                width={300}
            />
        );
    }
}

export default SigninLottie;