import { useRef, useState, useEffect } from "react";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import social2 from '../img/social.png';
import jbLogo from '../img/jb-logo.png';
import axios from './Api/axios';
import { Link } from "react-router-dom";

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = '/register';

const Register = () => {
  const userRef = useRef();
  const errRef = useRef();

  const [username, setUserName] = useState('');
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [password, setPassword] = useState('');
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {
    setValidName(USER_REGEX.test(username));
  }, [username])

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(password));
    setValidMatch(password === matchPwd);
  }, [password, matchPwd])

  useEffect(() => {
    setErrMsg('');
  }, [username, password, matchPwd])

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(username,password);
    // if button enabled with JS hack
    const v1 = USER_REGEX.test(username);
    const v2 = PWD_REGEX.test(password);
    if (!v1 || !v2) {
      setErrMsg("ورودی غیر مجاز");
      return;
    }
    // try {
    //   const response = await axios.post("http://94.182.215.118:8080",
    //     JSON.stringify({ username, password}),
    //     {
    //       headers: { 'Content-Type': 'application/json' },
    //       withCredentials: true
    //     }
    //   );
    //   console.log(response?.data);
    //   console.log(response?.accessToken);
    //   console.log(JSON.stringify(response))
    //   setSuccess(true);
    //   //clear state and controlled inputs
    //   //need value attrib on inputs for this
    //   setUserName('');
    //   setPassword('');
    //   setMatchPwd('');
    // } catch (err) {
    //   if (!err?.response) {
    //     setErrMsg('پاسخی از سرور دریافت نشد');
    //   } else if (err.response?.status === 409) {
    //     setErrMsg('نام کاربری آزاد نمی‌باشد');
    //   } else {
    //     setErrMsg('ثبت‌نام ناموفق بود')
    //   }
    //   errRef.current.focus();
    // }
  }

  return (
    <div className='grid bg-grad grid-cols-1 md:grid-cols-2 w-full min-h-screen'>
    <div className="Auth-form-container">
      {success ? (
        <section>
          <h1>ثبت‌نام موفق!</h1>
          <p>
            <Link to="/sign-in">ورود به ناحیه کاربری</Link>
          </p>
        </section>
      ) : (
        <section className='min-w-screen w-full flex justify-center items-center'>
          {/* <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p> */}
          <form onSubmit={handleSubmit} className="Auth-form mx-3 w-80 sm:w-128 py-10">
          <div className="text-center"><img className="img-fluid mb-3 inline" src={jbLogo} alt="logo" width={100}/></div>
            <div className="Auth-form-content">
              <h3 className="text-center text-zinc-900 font-extrabold mb-4">فرم ثبت نام کاربر</h3>
              <p className="text-center text-white">
                ناحیه کاربری داری؟{" "}
                <span className="link-primary">
                  <Link to="/sign-in" className=' text-middle-yellow outline-2 outline-slate-500'>وارد شو</Link>
                </span>
              </p>
              <div className="form-group mt-3">
                <label htmlFor="username" className='text-white'>
                  نام کاربری:
                  <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                  <FontAwesomeIcon icon={faTimes} className={validName || !username ? "hide" : "invalid"} />
                </label>
                <input
                  type="text"
                  id="username"
                  ref={userRef}
                  autoComplete="off"
                  className="block w-full py-2 px-3 rounded bg-white transition-all duration-200 ease-in mt-1 focus:shadow-slate-500 focus:shadow-md outline-2 outline-middle-yellow"
                  onChange={(e) => setUserName(e.target.value)}
                  value={username}
                  required
                  aria-invalid={validName ? "false" : "true"}
                  aria-describedby="uidnote"
                  onFocus={() => setUserFocus(true)}
                  onBlur={() => setUserFocus(false)}
                />
                <p id="uidnote" className={userFocus && username && !validName ? "instructions" : "offscreen"}>
                  <FontAwesomeIcon icon={faInfoCircle} />
                  شامل 4 تا 24 کاراکتر انگلیسی.<br />
                  میبایست با حروف انگلیسی شروع شود.<br />
                  حروف، اعداد، خط فاصله و خط زیر فاصله قابل قبول هستند.
                </p>
              </div>
              <div className="form-group mt-3">
                <label htmlFor="password" className='text-white'>
                  کلمه عبور:
                  <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                  <FontAwesomeIcon icon={faTimes} className={validPwd || !password ? "hide" : "invalid"} />
                </label>
                <input
                  type="password"
                  id="password"
                  className="block w-full py-2 px-3 rounded bg-white transition-all duration-200 ease-in mt-1 focus:shadow-slate-500 focus:shadow-md outline-2 outline-middle-yellow"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  required
                  aria-invalid={validPwd ? "false" : "true"}
                  aria-describedby="pwdnote"
                  onFocus={() => setPwdFocus(true)}
                  onBlur={() => setPwdFocus(false)}
                />
                <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                  <FontAwesomeIcon icon={faInfoCircle} />
                  شامل 8 تا 24 کاراکتر.<br />
                  میبایست شامل حروف بزرگ و کوچک، اعداد و علائم باشد.<br />
                  علائم قابل استفاده: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                </p>

              </div>
              <div className="form-group mt-3">
                <label htmlFor="confirm_pwd" className='text-white'>
                  تایید کلمه عبور:
                  <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                  <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                </label>
                <input
                  type="password"
                  id="confirm_pwd"
                  className="block w-full py-2 px-3 rounded bg-white transition-all duration-200 ease-in mt-1 focus:shadow-slate-500 focus:shadow-md outline-2 outline-middle-yellow"
                  onChange={(e) => setMatchPwd(e.target.value)}
                  value={matchPwd}
                  required
                  aria-invalid={validMatch ? "false" : "true"}
                  aria-describedby="confirmnote"
                  onFocus={() => setMatchFocus(true)}
                  onBlur={() => setMatchFocus(false)}
                />
                <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                  <FontAwesomeIcon icon={faInfoCircle} />
                  با کلمه عبور اول همخوانی ندارد.
                </p>
              </div>
              <div className="d-grid gap-2 mt-3">
              <button className=" bg-middle-yellow text-slate-50 hover:text-middle-yellow outline-2 outline-white hover:bg-white text-lg border-4 border-middle-yellow hover:shadow-middle-yellow rounded select-none transition-all duration-200 ease-in py-1 mt-2" disabled={!validName || !validPwd || !validMatch ? true : false}>ثبت‌نام</button>
              </div>
            </div>
          </form>
        </section>
      )}
    </div>
    <div className='hidden md:flex justify-center items-center pl-20'>
                <img src={social2} width={"80%"} />
            </div>
    </div>

  )
}

export default Register