import { useContext, useState, useEffect } from "react";
import SinNumInp from "./SinNumInp";
import BulkInp from "./BulkInp";
import InqTable from "./InqTable";
import InqSin from "./InqSin";
import AuthContext from './Context/AuthProvider';
import { faCheckSquare, faClipboardQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FollowerDet from "./FollowerDet";

function InstaDet() {

    const { setDetShow, show, setShow, tableShow } = useContext(AuthContext);

    return (
        <section className="inq-result">
            <div className="transition-all duration-200 ease-in" style={show ? { display: "none", opacity: 0, height: 0 } : { display: "block", opacity: 1, height: "auto" }}>
                <div className="">
                    <span className='inline-block mr-4 mt-4 text-green-800 text-lg ml-2'><FontAwesomeIcon icon={faCheckSquare} /></span>
                    <h5 className="inline-flex items-center">نتیجه استعلام</h5>
                    <button className="p-1 mb-4 hover:bg-yellow text-white bg-light-green rounded mr-2 select-none transition-all duration-200 ease-in" onClick={() => setShow(true)}>بازگشت</button>
                </div>
                <p className=' px-8'>
                    نتیجه استعلام مورد نظر شما به شرح زیر است.
                </p>
                {/* {tableShow ? <InqSin /> : ''}
                {tableShow ? '' : <FollowerDet /> } */}
                <FollowerDet />
            </div>
        </section>
    )

}

export default InstaDet
