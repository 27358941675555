import { useContext, useState, useEffect, useRef } from "react";
import axios from "./Api/axios";
import AuthContext from './Context/AuthProvider';
import contactImginsta from '../img/hipster-avatar-image-vector.jpg';
import contactImgTw from '../img/contact.svg';
import contactImgFa from '../img/hipster-avatar-image-vector.jpg';
import contactImgTel from '../img/sample-avatar-min.jpg';
import { faCheckSquare, faQuestionCircle, faXmarkSquare, faClipboardQuestion, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTelegram, faTwitter, faWhatsapp, faFacebook, faLinkedIn, faLinkedin, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import InqTable from "./InqTable";
import BulkInp from "./BulkInp";

function InpInq() {

    const { setDetShow, setSocailCh, setContactImg, inqData, inpKind, setInqData, inq, setInq, subAlert, setInpKind, setShow, setTableShow, setSubAlert, show, tableShow, inqShow, setInqShow } = useContext(AuthContext);
    const [input, setInput] = useState(null);
    const [loading, setLoading] = useState(false);

    const [userNum, setUserNum] = useState(null);
    const inputRef = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShow(false);
        setTableShow(true);
        setInput(userNum);
        setSubAlert(true);
        setInqShow(true);
        inputRef.current.value = '';
    };

    const dontHandle =  () => {
        alert("لطفا ورودی را مشخص کنید")
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getList = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`/check/${inpKind}/${input}`, {
                    signal: controller.signal
                });
                console.log(response);
                response?.data?.code == 403 && setLoading(false);
                isMounted && setInqData(response?.data);
                console.log(inqData);
                setInq(response?.data?.data[0]);
                setLoading(false);
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        }

        input && getList();

        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [input]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const MySubmit = async () => {
            try {
                setLoading(true);
                const res = await axios.post(`/add/${inpKind}`, { input });
                setLoading(false);
                console.log(res);
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        };

        inqData?.code == 300 && MySubmit();

        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [input, inqData]);

    const reportingDetails = () => {
        setDetShow(false);
    };
    return (
        <section className="inq-result">
            {
                !inqShow ? <div className="">
                    <span className='inline-block  place-content-center mr-4 text-blue-800 dark:text-blue-300 text-lg ml-2'><FontAwesomeIcon icon={faClipboardQuestion} /></span>
                    <h5 className="inline-flex items-center">استعلام اکانت</h5>
                    <div className="">
                        <div className="flex items-center px-8">
                            <p className="m-0">نوع استعلام را مشخص کنید:</p>
                            <ul className="m-0 flex">
                                <li onClick={() => { setInpKind("mobile"); }} className={`p-2 hover:bg-yellow text-white text-sm mr-1  ${inpKind === "mobile" ? "bg-dark-blue cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`}><span>شماره موبایل </span></li>
                                <li onClick={() => { setInpKind("id"); }} className={`p-2 hover:bg-yellow text-white text-sm mr-1  ${inpKind === "id" ? "bg-dark-blue cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`}><span>شناسه کاربری </span></li>
                                <li onClick={() => { setInpKind("username"); }} className={`p-2 hover:bg-yellow text-white text-sm mr-1  ${inpKind === "username" ? "bg-dark-blue cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`}><span>نام کاربری </span></li>
                            </ul>
                        </div>
                        <div className="mt-4">
                            <div className="lg:w-1/2 inq-holder">
                                <div className="mx-auto text-center">
                                    <form onSubmit={handleSubmit}>
                                        <label className="ml-2">ورود مشخصه تکی:</label>
                                        <input type="text" pattern="^(0|0098|\+98)9(0[1-5]|[1 3]\d|2[0-2]|98)\d{7}$" ref={inputRef} title="شماره موبایل را به صورت صحیح وارد کنید." onChange={(e) => setUserNum(e.target.value)} className="border-2 border-bg-light-green w-1/2 h-10 py-2 px-3 mx-auto rounded bg-white transition-all duration-200 ease-in mt-1 focus:shadow-slate-500 focus:shadow-md outline-2 outline-middle-yellow" />
                                        {<button type={`${userNum ? "submit" : "button"}`} onClick={userNum ? console.log("submit") : dontHandle} className={`py-2 px-4 h-10 subBut text-white hover:bg-yellow bg-light-green rounded mr-2 mt-2 cursor-pointer`}>ثبت</button>}
                                    </form>
                                </div>
                            </div>
                            <BulkInp />
                        </div>
                    </div>
                </div>
                    : <div className="transition-all duration-200 ease-in" style={show ? { display: "none", opacity: 0, height: 0 } : { display: "block", opacity: 1, height: "auto" }}>
                        <h5 className='inline-flex items-center'><span className='inline-block place-content-center align-top text-green-600 text-lg ml-2'><FontAwesomeIcon icon={faCheckSquare} /></span><span>نتیجه استعلام</span><button className={`py-1 px-2 hover:bg-yellow text-white text-base mr-1 bg-light-green rounded select-none transition-all duration-200 ease-in`} onClick={() => {setInqShow(false); setUserNum(null)}}>بازگشت</button>
                        </h5>
                        {
                            loading ? <div className='flex'>< p className=' text-right text-lg mt-2 pr-6' > در حال بارگذاری...</p></div>
                            : tableShow ? <>
                            <p className=' px-8'>
                                <span className="inline-block ml-1">برای مشاهده جزئیات هر اکانت بر روی گزینه مورد نظر کلیک نمایید.</span>
                                {
                                    inqData?.code == 300 ? <span className=" bg-green-200 inline-block p-2 w-fit mx-auto text-center text-sm rounded mt-8 transition-all duration-200 ease-in select-none" style={subAlert ? { "visibility": "visible", "opacity": 1 } : { "visibility": "hidden", "opacity": 0 }}>ورودی از قبل در سیستم وجود نداشت و اضافه شد.</span> : ''
                                }
                            </p>
                            <div className="flex lg:w-1/2 mx-auto mt-4 flex-col justify-center bg-gray-200 shadow-sm shadow-middle-green rounded">
                                <div className="flex justify-between items-center bg-middle-green text-white border-b border-b-gray-400 rounded-t p-2">
                                    <div className="flex items-center"><span className="">شماره: {inqData?.mobile}</span></div>
                                </div>
                                <div onClick={() => { inq?.status == 3 && reportingDetails(); setSocailCh("insta"); setContactImg(contactImginsta) }} className="flex justify-between items-center border-b border-b-gray-400 p-1 cursor-pointer hover:bg-gray-300 transition-all duration-100 ease-in">
                                    <div className="flex items-center mr-4"><span className='inline-block place-content-center align-top fill-gradient-instagram text-white text-lg ml-2'><FontAwesomeIcon icon={faInstagram} /></span><span className="">اینستاگرام</span></div>
                                    <div className="flex items-center w-16 justify-center">
                                        {inqData?.code == 200 && <span>
                                            {inq?.status == 3 && <span className='inline-block place-content-center align-top text-green-500 text-lg ml-2'><FontAwesomeIcon icon={faCheckSquare} /></span>}
                                            {inq?.status == 2 && <span className='inline-block place-content-center align-top text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faXmarkSquare} /></span>}
                                            {inq?.status == 1 && <span className='inline-block place-content-center align-top text-blue-500 text-base w-40 ml-2'>در انتظار استعلام</span>}
                                            {inq?.status == 0 && <span className='inline-block place-content-center align-top text-gray-500 text-base w-40 ml-2'>در انتظار بررسی</span>}
                                        </span>
                                        }
                                        {
                                            inqData?.code == 300 && <span className='inline-block place-content-center align-top text-gray-500 text-lg ml-2'><FontAwesomeIcon icon={faQuestionCircle} /></span>
                                        }
                                    </div>
                                </div>
                                <div onClick={() => { inq?.telegram_status == 3 && reportingDetails(); setSocailCh("telegram"); setContactImg(contactImgTel) }} className="flex justify-between items-center border-b border-b-gray-400 p-1 cursor-pointer hover:bg-gray-300 transition-all duration-100 ease-in">
                                    <div className="flex items-center mr-4"><span className='inline-block place-content-center align-top text-blue-600 text-lg ml-2'><FontAwesomeIcon icon={faTelegram} /></span><span className="">تلگرام</span></div>
                                    <div className="flex items-center w-16 justify-center">
                                        {inqData?.code == 200 && <span>
                                            {inq?.telegram_status == 3 && <span className='inline-block place-content-center align-top text-green-500 text-lg ml-2'><FontAwesomeIcon icon={faCheckSquare} /></span>}
                                            {inq?.telegram_status == 2 && <span className='inline-block place-content-center align-top text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faXmarkSquare} /></span>}
                                            {inq?.telegram_status == 1 && <span className='inline-block place-content-center align-top text-blue-500 text-base w-40 ml-2'>در انتظار استعلام</span>}
                                            {inq?.telegram_status == 0 && <span className='inline-block place-content-center align-top text-gray-500 text-base w-40 ml-2'>در انتظار بررسی</span>}
                                        </span>
                                        }
                                        {
                                            inqData?.code == 300 && <span className='inline-block place-content-center align-top text-gray-500 text-lg ml-2'><FontAwesomeIcon icon={faQuestionCircle} /></span>
                                        }
                                    </div>
                                </div>
                                <div onClick={() => { inq?.whatsapp_status == 3 && reportingDetails(); setSocailCh("whatsapp"); setContactImg(contactImgTw) }} className="flex justify-between items-center border-b border-b-gray-400 p-1 cursor-pointer hover:bg-gray-300 transition-all duration-100 ease-in">
                                    <div className="flex items-center mr-4"><span className='inline-block place-content-center align-top text-green-600 text-lg ml-2'><FontAwesomeIcon icon={faWhatsapp} /></span><span className="">واتساپ</span></div>
                                    <div className="flex items-center w-16 justify-center">
                                        {inqData?.code == 200 && <span>
                                            {inq?.whatsapp_status == 3 && <span className='inline-block place-content-center align-top text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faCheckSquare} /></span>}
                                            {inq?.whatsapp_status == 2 && <span className='inline-block place-content-center align-top text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faXmarkSquare} /></span>}
                                            {inq?.whatsapp_status == 1 && <span className='inline-block place-content-center align-top text-blue-500 text-base w-40 ml-2'>در انتظار استعلام</span>}
                                            {inq?.whatsapp_status == 0 && <span className='inline-block place-content-center align-top text-gray-500 text-base w-40 ml-2'>در انتظار بررسی</span>}
                                        </span>
                                        }
                                        {
                                            inqData?.code == 300 && <span className='inline-block place-content-center align-top text-gray-500 text-lg ml-2'><FontAwesomeIcon icon={faQuestionCircle} /></span>
                                        }
                                    </div>
                                </div>
                                <div onClick={() => { inq?.x_status == 3 && reportingDetails(); setSocailCh("twitter"); setContactImg(contactImgTw) }} className="flex justify-between items-center border-b border-b-gray-400 p-1 cursor-pointer hover:bg-gray-300 transition-all duration-100 ease-in">
                                    <div className="flex items-center mr-4"><span className='inline-block place-content-center align-top text-black text-lg ml-2'><FontAwesomeIcon icon={faXTwitter} /></span><span className="">ایکس(توییتر)</span></div>
                                    <div className="flex items-center w-16 justify-center">
                                        {inqData?.code == 200 && <span>
                                            {inq?.x_status == 3 && <span className='inline-block place-content-center align-top text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faCheckSquare} /></span>}
                                            {inq?.x_status == 2 && <span className='inline-block place-content-center align-top text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faXmarkSquare} /></span>}
                                            {inq?.x_status == 1 && <span className='inline-block place-content-center align-top text-blue-500 text-base w-40 ml-2'>در انتظار استعلام</span>}
                                            {inq?.x_status == 0 && <span className='inline-block place-content-center align-top text-gray-500 text-base w-40 ml-2'>در انتظار بررسی</span>}
                                        </span>
                                        }
                                        {
                                            inqData?.code == 300 && <span className='inline-block place-content-center align-top text-gray-500 text-lg ml-2'><FontAwesomeIcon icon={faQuestionCircle} /></span>
                                        }
                                    </div>
                                </div>
                                <div onClick={() => { inq?.linkedin_status == 3 && reportingDetails(); setSocailCh("linkedin"); setContactImg(contactImgFa) }} className="flex justify-between items-center border-b border-b-gray-400 p-1 cursor-pointer hover:bg-gray-300 transition-all duration-100 ease-in">
                                    <div className="flex items-center mr-4"><span className='inline-block place-content-center align-top text-blue-700 text-lg ml-2'><FontAwesomeIcon icon={faLinkedin} /></span><span className="">لینکدین</span></div>
                                    <div className="flex items-center w-16 justify-center">
                                        {inqData?.code == 200 && <span>
                                            {inq?.linkedin_status == 3 && <span className='inline-block place-content-center align-top text-green-500 text-lg ml-2'><FontAwesomeIcon icon={faCheckSquare} /></span>}
                                            {inq?.linkedin_status == 2 && <span className='inline-block place-content-center align-top text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faXmarkSquare} /></span>}
                                            {inq?.linkedin_status == 1 && <span className='inline-block place-content-center align-top text-blue-500 text-base w-40 ml-2'>در انتظار استعلام</span>}
                                            {inq?.linkedin_status == 0 && <span className='inline-block place-content-center align-top text-gray-500 text-base w-40 ml-2'>در انتظار بررسی</span>}
                                        </span>
                                        }
                                        {
                                            inqData?.code == 300 && <span className='inline-block place-content-center align-top text-gray-500 text-lg ml-2'><FontAwesomeIcon icon={faQuestionCircle} /></span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </> : ''
                        }
                        {/* {tableShow ? <>
                            <p className=' px-8'>
                                <span className="inline-block ml-1">برای مشاهده جزئیات هر اکانت بر روی گزینه مورد نظر کلیک نمایید.</span>
                                {
                                    inqData?.code == 300 ? <span className=" bg-green-200 inline-block p-2 w-fit mx-auto text-center text-sm rounded mt-8 transition-all duration-200 ease-in select-none" style={subAlert ? { "visibility": "visible", "opacity": 1 } : { "visibility": "hidden", "opacity": 0 }}>ورودی از قبل در سیستم وجود نداشت و اضافه شد.</span> : ''
                                }
                            </p>
                            <div className="flex lg:w-1/2 mx-auto mt-4 flex-col justify-center bg-gray-200 shadow-sm shadow-middle-green rounded">
                                <div className="flex justify-between items-center bg-middle-green text-white border-b border-b-gray-400 rounded-t p-2">
                                    <div className="flex items-center"><span className="">شماره: {numInp}</span></div>
                                </div>
                                <div onClick={() => { reportingDetails(); setSocailCh("insta"); setContactImg(contactImginsta) }} className="flex justify-between items-center border-b border-b-gray-400 p-1 cursor-pointer hover:bg-gray-300 transition-all duration-100 ease-in">
                                    <div className="flex items-center mr-4"><span className='inline-block place-content-center align-top fill-gradient-instagram text-white text-lg ml-2'><FontAwesomeIcon icon={faInstagram} /></span><span className="">اینستاگرام</span></div>
                                    <div className="flex items-center w-16 justify-center">
                                        {inqData?.code == 200 && <span>
                                            {inq?.status == 3 && <span className='inline-block place-content-center align-top text-green-500 text-lg ml-2'><FontAwesomeIcon icon={faCheckSquare} /></span>}
                                            {inq?.status == 2 && <span className='inline-block place-content-center align-top text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faXmarkSquare} /></span>}
                                            {inq?.status == 1 && <span className='inline-block place-content-center align-top text-blue-500 text-base w-40 ml-2'>در انتظار استعلام</span>}
                                            {inq?.status == 0 && <span className='inline-block place-content-center align-top text-gray-500 text-base w-40 ml-2'>در انتظار بررسی</span>}
                                        </span>
                                        }
                                        {
                                            inqData?.code == 300 && <span className='inline-block place-content-center align-top text-gray-500 text-lg ml-2'><FontAwesomeIcon icon={faQuestionCircle} /></span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </> : ''} */}
                        {tableShow ? '' : <InqTable />}
                    </div>
            }
        </section>
    )

}

export default InpInq
