import { useContext } from "react";
import AuthContext from './Context/AuthProvider';
import Header from "./Header";
import Result from "./Results";
import TotalResult from "./TotalResult";
import InpInq from "./InpInq";

function NumInq() {

    const { detShow, inqData, setDetShow } = useContext(AuthContext);

    return (
        <>
            <Header />
            <section className="dark:bg-stone-900 dark:text-gray-100 transition-all duration-100 ease-in h-screen">
                <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 h-screen overflow-y-auto overflow-x-hidden scroll-design'>
                    <div className="md:col-span-7 md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
                        {detShow ? <InpInq /> : ''}
                        {!detShow ? <>
                            {inqData?.code == 200 ? <Result /> : <div className="flex justify-center mt-10"><div className="text-center bg-blue-200 p-2 rounded-sm w-fit">فرآیند استعلام انجام نشده است.</div>
                                <button className={`py-1 px-2 hover:bg-yellow text-white text-base mr-1 bg-light-green rounded-sm select-none transition-all duration-200 ease-in`} onClick={() => setDetShow(true)}>بازگشت</button>
                            </div>
                            }
                        </> : ""
                        }
                    </div>
                </div>
            </section>
        </>
    )

}

export default NumInq
