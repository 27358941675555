import axios from '../Api/axios';
import useAuth from "../Hooks/useAuth";

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get('/api/auth/users/me', {
            withCredentials: true
        });
        setAuth(prev => {
            console.log(prev);
            console.log(response.data.access);
            console.log("refresh is working");
            return { ...prev, access: response.data.access }
        });
        return response.data.access;
    }
    return refresh;
};

export default useRefreshToken;