import axios from 'axios';
const BASE_URL = 'https://api.saber24.ir';
const token = localStorage.getItem('access');

export default axios.create({
    baseURL: BASE_URL,
    headers: {
        // 'Content-Type': 'application/json',
    },
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    // withCredentials: true
});

// export const axiosPrivate = axios.create({
//     baseURL: BASE_URL,
//     // headers: { 'Content-Type': 'application/json' },
//     // withCredentials: true
// });