import { useContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import contactImage from '../img/contact.svg';
import gptLogo from '../img/ChatGPT_logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faMessage, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments';
function ChatBox() {

    const [opChat, setOpChat] = useState()
    const [input, setInput] = useState(null);
    const [loading, setLoading] = useState(false);

    const openChat = () => {
        setOpChat(true);
    }

    const inputRef = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const res = await axios.post(`http://94.182.215.124:21181/api/generate/generate`, { input },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            console.log(res);
            console.log(input);
            setLoading(false);
        } catch (err) {
            console.error(err);
            // navigate('/sign-in', { state: { from: location }, replace: true });
        }
        inputRef.current.value = '';
        console.log(input)
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const MySubmit = async () => {
            try {
                setLoading(true);
                const res = await axios.post(`http://94.182.215.124:21181/api/generate/generate`, { input });
                console.log(res);
                setLoading(false);
                console.log(res);
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        };



        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, []);
    return (
        <>
            <div className='grid md:grid-cols-7 w-full overflow-x-hidden'>
                <div className="md:col-span-7 rounded-md md:rounded-l-md md:rounded-r-none">
                    <div className='grid grid-cols-1 md:grid-cols-4 xl:grid-cols-5'>
                        <div className={`${!opChat ? "block" : "hidden"} md:block w-full max-h-screen overflow-y-auto bg-gray-100`}>
                            <div className="flex gap-1 items-center justify-between h-9 rounded-md p-2 lg:px-6 mx-2 hover:bg-gray-200 cursor-pointer mt-4" onClick={() => openChat()}>
                                {/* <div className=""><img src={contactImage} className="rounded-full" width={50} height={50} /></div> */}
                                <div className="flex items-center mr-2">
                                    {/* <h6 className="text-sm xl:text-base mb-1 pt-1">سپهر احمدی
                                        </h6> */}
                                    <span className='inline-block place-content-center text-black text-base ml-2'><FontAwesomeIcon icon={faComments} /></span>
                                    <p className="m-0 text-xs xl:text-base">
                                        چت جدید
                                    </p>
                                </div>
                                <div><span className='inline-block place-content-center mr-4 text-black text-lg ml-2'><FontAwesomeIcon icon={faPenToSquare} /></span></div>
                                {/* <p className="m-0 pl-4 text-base w-1/3 text-left">
                                    23:45
                                </p> */}
                            </div>
                            <div className="flex gap-1 items-center justify-between h-9 rounded-md bg-gray-200 p-2 lg:px-6 mx-2 hover:bg-gray-200 cursor-pointer mt-4" onClick={() => openChat()}>
                                <div className='flex w-2/3'>
                                    {/* <div className=""><img src={contactImage} className="rounded-full" width={50} height={50} /></div> */}
                                    <div className="mr-2">
                                        {/* <h6 className="text-sm xl:text-base mb-1 pt-1">سپهر احمدی
                                        </h6> */}
                                        <p className="m-0 text-xs xl:text-base">
                                            سلام چطوری؟
                                        </p>
                                    </div>
                                </div>
                                {/* <p className="m-0 pl-4 text-base w-1/3 text-left">
                                    23:45
                                </p> */}
                            </div>
                            <div className="flex gap-1 items-center justify-between h-9 rounded-md p-2 lg:px-6 mx-2 hover:bg-gray-200 cursor-pointer">
                                <div className='flex w-2/3'>
                                    {/* <div className=""><img src={contactImage} className="rounded-full" width={50} height={50} /></div> */}
                                    <div className="mr-2">
                                        {/* <h6 className="text-sm xl:text-base mb-1 pt-1">علی رایی
                                        </h6> */}
                                        <p className="m-0 text-xs xl:text-base">
                                            سلام چطوری؟
                                        </p>
                                    </div>
                                </div>
                                {/* <p className="m-0 pl-4 text-base w-1/3 text-left">
                                    09:10
                                </p> */}
                            </div>
                        </div>
                        <div className={`${opChat ? "block" : "hidden"} md:flex flex-col md:col-span-3 xl:col-span-4 w-ful max-h-screen bg-white relative pb-4`}>
                            <div className="flex w-full gap-1 items-center h-9 rounded-md bg-white py-10 px-6 sticky top-0">
                                <div className="md:hidden w-10 h-10 z-50 flex justify-end">
                                    <button className=" p-2 rounded text-white ransition-all duration-200 ease-in" onClick={() => setOpChat(false)}>
                                        <span className='text-xl text-middle-green'>
                                            <FontAwesomeIcon icon={faArrowRight} />
                                        </span>
                                    </button>
                                </div>
                                <div className="mr-2">
                                    <h6 className="text-sm text-left lg:text-base mb-1 pt-1">
                                        ChatGPT 3.5
                                    </h6>
                                </div>
                            </div>
                            <div className='bg-white overflow-y-auto scroll-design overflow-x-hidden p-6'>
                                <div className='flex items-start py-1 pr-1 md:pl-20 lg:pl-40 mb-3'>
                                    <div className='shrink-0'>
                                        <img className="rounded-full object-cover w-8" src={contactImage} width={30} />
                                    </div>
                                    <div className="chat-bubble__right px-2 mr-2">
                                        <p className="m-0 text-sm md:text-sm">سلام. لطفا چند کتاب به من معرفی کن</p>
                                    </div>
                                </div>
                                <div className='flex items-start py-1 pr-1 md:pl-20 lg:pl-40 mb-3'>
                                    <div className='shrink-0'>
                                        <img className="rounded-full object-cover w-8" src={gptLogo} width={30} />
                                    </div>
                                    <div className="chat-bubble__right px-2 mr-2">
                                        <p className="m-0 text-sm md:text-sm">سلام. ممنونم که با من ارتباط گرفتی. چه موضوعاتی مورد علاقه شماست؟</p>
                                    </div>
                                </div>

                                {/* sample data */}

                                <div className='flex items-start py-1 pr-1 md:pl-20 lg:pl-40 mb-3'>
                                    <div className='shrink-0'>
                                        <img className="rounded-full object-cover w-8" src={contactImage} width={30} />
                                    </div>
                                    <div className="chat-bubble__right px-2 mr-2">
                                        <p className="m-0 text-sm md:text-sm">خواهش میکنم. اثار ادبیات کلاسیک</p>
                                    </div>
                                </div>
                                <div className='flex items-start py-1 pr-1 md:pl-20 lg:pl-40 mb-3'>
                                    <div className='shrink-0'>
                                        <img className="rounded-full object-cover w-8" src={gptLogo} width={30} />
                                    </div>
                                    <div className="chat-bubble__right px-2 mr-2">
                                        <p className="m-0 text-sm md:text-sm">بسیار عالی. جنگ و صلح، بینوایان، کیمیاگر و مرشد و مارگاریتا</p>
                                    </div>
                                </div>                                                    <div className='flex items-start py-1 pr-1 md:pl-20 lg:pl-40 mb-3'>
                                    <div className='shrink-0'>
                                        <img className="rounded-full object-cover w-8" src={contactImage} width={30} />
                                    </div>
                                    <div className="chat-bubble__right px-2 mr-2">
                                        <p className="m-0 text-sm md:text-sm">ممنونم. چند کتاب ایران هم به من معرفی کن</p>
                                    </div>
                                </div>
                                <div className='flex items-start py-1 pr-1 md:pl-20 lg:pl-40 mb-3'>
                                    <div className='shrink-0'>
                                        <img className="rounded-full object-cover w-8" src={gptLogo} />
                                    </div>
                                    <div className="chat-bubble__right px-2 mr-2">
                                        <p className="m-0 text-sm md:text-sm">کلیدر، چشم‌هایش و سو و شون از جمله آثار ادبیات کلاسیچشم‌هایش و سو و شون از جمله آثار ادبیات کلاسیچشم‌هایش و سو و شون از جمله آثار ادبیات کلاسیچشم‌هایش و سو و شون از جمله آثار ادبیات کلاسیچشم‌هایش و سو و شون از جمله آثار ادبیات کلاسیچشم‌هایش و سو و شون از جمله آثار ادبیات کلاسیچشم‌هایش و سو و شون از جمله آثار ادبیات کلاسیچشم‌هایش و سو و شون از جمله آثار ادبیات کلاسیک هستند.</p>
                                    </div>
                                </div>
                                <div className='flex items-start py-1 pr-1 md:pl-20 lg:pl-40 mb-3'>
                                    <div className='shrink-0'>
                                        <img className="rounded-full object-cover w-8" src={contactImage} width={30} />
                                    </div>
                                    <div className="chat-bubble__right px-2 mr-2">
                                        <p className="m-0 text-sm md:text-sm">سو و شون نوشته کیست؟</p>
                                    </div>
                                </div>
                                <div className='flex items-start py-1 pr-1 md:pl-20 lg:pl-40 mb-3'>
                                    <div className='shrink-0'>
                                        <img className="rounded-full object-cover w-8" src={gptLogo} width={30} />
                                    </div>
                                    <div className="chat-bubble__right px-2 mr-2">
                                        <p className="m-0 text-sm md:text-sm">سیمین دانشور</p>
                                    </div>
                                </div>
                                {/* end of sample data */}
                            </div>
                            <div className="w-full sticky bottom-0 right-60 md:border-transparent md:w-[calc(100%-.5rem)] p-0">
                                <div className="px-3 text-base md:px-4 m-auto lg:px-1 xl:px-5">
                                    <div className="mr-2 md:pl-10 lg:pl-40 flex flex-1 gap-3 text-base">
                                        <form onSubmit={handleSubmit} className="w-full"><div className="relative flex h-full max-w-full flex-1 flex-col">
                                            <div className="absolute bottom-full left-0 right-0"></div>
                                            <div className="flex w-full items-center">
                                                <div className="overflow-hidden flex flex-col w-full flex-grow relative border rounded-2xl">
                                                    <textarea id="prompt-textarea" ref={inputRef} onChange={(e) => setInput(e.target.value)} tabIndex="0" data-id="" dir="auto" rows="1" placeholder="Message ChatGPT" className="m-0 w-full resize-none border-0 bg-transparent focus:ring-0 focus-visible:ring-0 py-[10px] pr-10 md:py-3.5 md:pr-12 max-h-[25dvh] placeholder-black/50 pl-4 md:pl-6 overflow-y-hidden"></textarea>
                                                    <button type="submit" disabled="" className="absolute bottom-1.5 right-2 rounded-lg border border-black bg-black p-0.5 text-white transition-colors enabled:bg-black disabled:text-gray-400 disabled:opacity-10 md:bottom-3 md:right-3" data-testid="send-button">
                                                        <span className="" data-state="closed">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="text-white"><path d="M7 11L12 6L17 11M12 18V7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        </form>
                                    </div>
                                </div>
                                {/* <div className="relative px-2 pt-2 text-center text-xs text-token-text-secondary md:px-[60px]">
                                    <span>ChatGPT can make mistakes. Consider checking important information.</span>
                                </div> */}
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default ChatBox