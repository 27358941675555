import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Header from './Header';
import image from '../JsonFiles/GalleryImage.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faImage, faXmark } from '@fortawesome/free-solid-svg-icons';
import data from '../JsonFiles/GalleryImage.json'


const Gallery = () => {

  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (i) => {
    setSlideNumber(i)
    setOpenModal(true)
  }

  // Close Modal
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(data.length - 1)
      : setSlideNumber(slideNumber - 1)
  }

  // Next Image  
  const nextSlide = () => {
    slideNumber + 1 === data.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1)
  }


  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 12;

  useEffect(() => {
    setTotalPages(Math.ceil(image.length / itemsPerPage));
  }, []);

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const subset = image.slice(startIndex, endIndex);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  return (

    <>
      <Header />
      <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 overflow-y-auto'>
                <div className="md:col-span-7 bg-white md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
                <span className='inline-block  place-content-center mr-4 text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faImage} /></span>
                <h5 className="inline-flex items-center">گالری</h5>
                <p className=' px-8 text-sm md:text-base'>
            لیست تصاویر کالری در این بخش قابل مشاهده است.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 rounded-md px-4 pb-4">
            {subset.map((image, i) => (
              <div key={i} className="grid gap-1 items-center justify-center w-full rounded-md bg-gray-100 mb-1 p-2">
                {openModal &&
                  <div className='sliderWrap'>
                    <FontAwesomeIcon icon={faXmark} className='btnClose' onClick={handleCloseModal} />
                    <FontAwesomeIcon icon={faChevronLeft} className='btnPrev' onClick={prevSlide} />
                    <FontAwesomeIcon icon={faChevronRight} className='btnNext' onClick={nextSlide} />
                    <div className='fullScreenImage'>
                      <img src={data[slideNumber].img} alt={image.path} />
                      <div className='gallery-caption max-w-full'>
                        <p className="text-center text-white m-0 text-xs mt-1">
                          <span>{data[slideNumber].path}</span><span>{data[slideNumber].date} - {data[slideNumber].time}</span><span>{data[slideNumber].size}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                }
                <div className="flex flex-col justify-center galleryWrap">
                  <div onClick={() => handleOpenModal(i)} className='single'>
                    <img src={image.img} className="" alt={image.path} />
                  </div>
                  <h6 className="text-sm text-center mb-1 pt-1">{image.path} + {currentPage}
                  </h6>
                  <p className="text-center m-0 text-xs">
                    {image.date} hi {image.time}
                  </p>
                  <p className="text-center m-0 text-xs">
                    {image.size}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div className='pr-6'>
            <ReactPaginate
              className='pagination-container flex items-center p-0 m-0 rounded-md w-fit'
              pageCount={totalPages}
              onPageChange={handlePageChange}
              forcePage={currentPage}
              previousLabel={"<<"}
              nextLabel={">>"}
              breakLabel={"..."}
              containerClassName={"pagination-container"}
              activeClassName={"active-page"}
            />
          </div>

          <div>
            {/* <br />
        <Users />
        <br /> */}
          </div>

        </div>
      </div>



    </>
  )
}

export default Gallery

