import { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import AuthContext from './Context/AuthProvider';
import Header from "./Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft, faXmark } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from 'react-paginate';
import axios from "./Api/axios";
import contactImg from '../img/contact.svg';

function FollowHistory() {

    const { setShow, setUserName } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [numList, setNumList] = useState();
    const [inpKind, setInpKind] = useState("follower");
    const [fullBio, setFullBio] = useState();
    const [openBio, setOpenBio] = useState(false);
    const [progress, setProgress] = useState(false);

    const handleOpenbio = () => {
        setOpenBio(true);
    }

    // Close Modal
    const handleClosebio = () => {
        setOpenBio(false)
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getList = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`/list/${inpKind}`, {
                    signal: controller.signal
                });
                isMounted && setNumList(response?.data?.slice(0).reverse());
                // setProgress(Number(numList?.page)/Number(numList?.max_page));
                setLoading(false);
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        }

        getList();
        console.log(numList?.page);
        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [inpKind])
    console.log(numList);

    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10;

    console.log(numList);

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const subset = numList?.slice(startIndex, endIndex);

    useEffect(() => {
        setTotalPages(Math.ceil(numList?.length / itemsPerPage));
    }, [subset]);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "*",
            "Access-Control-Allow-Haders": "*",
            "Accept": "*/*"
        }
    };

    // const download = async (e) => {
    //     try {
    //         const response = await axios.get("https://test.coderlife.ir/img/1926734655.jpg", axiosConfig);
    //         response.arrayBuffer().then(function (buffer) {
    //             const url = window.URL.createObjectURL(new Blob([buffer]));
    //             const link = document.createElement("a");
    //             link.href = url;
    //             link.setAttribute("download", "image.jpg"); //or any other extension
    //             document.body.appendChild(link);
    //             link.click();
    //         });
    //     } catch (err) {
    //         console.error(err);
    //         // navigate('/sign-in', { state: { from: location }, replace: true });
    //     }
    //     // console.log(e.target.href);
    //     // fetch("https://test.coderlife.ir/img/1926734655.jpg", {
    //     //   method: "GET",
    //     //   headers: {}
    //     // })
    //     //   .then(response => {
    //     //     response.arrayBuffer().then(function(buffer) {
    //     //       const url = window.URL.createObjectURL(new Blob([buffer]));
    //     //       const link = document.createElement("a");
    //     //       link.href = url;
    //     //       link.setAttribute("download", "image.jpg"); //or any other extension
    //     //       document.body.appendChild(link);
    //     //       link.click();
    //     //     });
    //     //   })
    //     //   .catch(err => {
    //     //     console.log(err);
    //     //   });
    // };

    return (
        <>
            <Header />
            <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 overflow-x-hidden'>
                <div className="md:col-span-7 md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
                    <span className='inline-block place-content-center mr-4 text-green-800 text-lg ml-2'><FontAwesomeIcon icon={faClockRotateLeft} /></span>
                    <h5 className="inline-flex items-center">تاریخچه استعلام</h5>
                    <div className="flex items-center px-8">
                        <p className="m-0">نوع استعلام را مشخص کنید:</p>
                        <ul className="m-0 flex">
                            {/* <li onClick={() => { setInpKind("all"); }} className={`p-2 hover:bg-yellow text-white text-sm mr-1  ${inpKind === "all" ? "bg-dark-blue cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`}><span>همه موارد </span>{inpKind == "all" && <span>({numList?.length})</span>}</li> */}
                            <li onClick={() => { setInpKind("follower"); }} className={`p-2 hover:bg-yellow text-white text-sm mr-1  ${inpKind === "follower" ? "bg-dark-blue cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`}><span>follower</span>{inpKind == "follower" && <span>({numList?.length})</span>}</li>
                            <li onClick={() => { setInpKind("following"); }} className={`p-2 hover:bg-yellow text-white text-sm mr-1  ${inpKind === "following" ? "bg-dark-blue cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`}><span>following</span>{inpKind == "following" && <span>({numList?.length})</span>}</li>
                            {/* <li onClick={() => { setInpKind("post"); }} className={`p-2 hover:bg-yellow text-white text-sm mr-1  ${inpKind === "post" ? "bg-dark-blue cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`}><span>post</span>{inpKind == "post" && <span>({numList?.length})</span>}</li> */}
                        </ul>
                    </div>
                    <div className='pl-8 mt-2 md:mt-0 flex justify-end'>
                        {
                            !loading ? <div className='pr-6'>
                                {numList?.length > 10 ? <ReactPaginate
                                    className='pagination-container flex items-center p-0 m-0 rounded-md w-fit'
                                    pageCount={totalPages}
                                    onPageChange={handlePageChange}
                                    forcePage={currentPage}
                                    previousLabel={"<<"}
                                    nextLabel={">>"}
                                    breakLabel={"..."}
                                    containerClassName={"pagination-container"}
                                    activeClassName={"active-page"}
                                />
                                    : ''
                                }
                            </div>
                                : ''
                        }
                    </div>
                    <div className="news-card grid grid-cols-1 gap-x-4 mt-2 rounded-md px-2 inner-scroll-250 overflow-y-auto">
                        {loading ? <div className='flex'>< p className=' text-right text-lg mt-2 pr-6' > در حال بارگذاری...</p></div>
                            : subset?.map((log, i) => (
                                // log.mobile &&
                                <div key={i} className="w-full bg-gray-50 hover:bg-blue-200 transition-all duration-200 ease-in border border-slate-400 rounded-md mb-3 p-3">
                                    <div className="news-card-header col-span-3">
                                        <div className='flex md:flex-col lg:flex-row justify-between'>
                                            <div className='flex flex-col md:flex-row justify-between items-center my-6 w-full'>
                                                <div className="flex flex-col md:flex-row justify-center items-center">
                                                    <div className="md:ml-4">
                                                        {
                                                            log?.profile_image ? <a href={log?.profile_image} download target="_blank"><img src={log?.profile_image} alt="تصویر پروفایل" width={100} className="rounded-full" /></a>
                                                                : <img src={contactImg} alt="تصویر پروفایل" width={100} className="rounded-full" />
                                                        }
                                                    </div>
                                                    <div className='flex justify-between my-2 md:my-0'>
                                                        <div className="flex">
                                                            <div className="md:ml-4">
                                                                <p className="m-0 text-sm mt-1 text-gray-600">
                                                                    شماره تماس:
                                                                    {
                                                                        log?.status === "3" ? <span>{log?.mobile ? ` ${log?.mobile}` : " ندارد "}</span>
                                                                            : log?.status === "2" ? <span> ندارد </span>
                                                                                : log?.status === "1" ? <span>{log?.mobile ? ` ${log?.mobile}` : " ... "}</span>
                                                                                    : log?.status === "0" ? <span>{log?.mobile ? ` ${log?.mobile}` : " ... "}</span>
                                                                                        : "..."
                                                                    }
                                                                </p>
                                                                <p className="m-0 text-sm mt-1 text-gray-600">
                                                                    نام کاربری:
                                                                    {
                                                                        log?.status === "3" ? <span>{log?.username ? ` ${log?.username}` : " ندارد "}</span>
                                                                            : log?.status === "4" ? <span>{log?.username ? ` ${log?.username}` : " ندارد "}</span>
                                                                                : log?.status === "2" ? <span>{log?.username ? ` ${log?.username}` : " ندارد "}</span>
                                                                                    : log?.status === "1" ? <span>{log?.username ? ` ${log?.username}` : " ... "}</span>
                                                                                        : log?.status === "0" ? <span>{log?.username ? ` ${log?.username}` : " ... "}</span>
                                                                                            : "..."
                                                                    }
                                                                </p>

                                                                <p className="m-0 text-sm mt-1 text-gray-600">
                                                                    شناسه کاربری:
                                                                    {
                                                                        log?.status === "3" ? <span>{log?.user_id ? ` ${log?.user_id}` : " ندارد "}</span>
                                                                            : log?.status === "4" ? <span>{log?.user_id ? ` ${log?.user_id}` : " ندارد "}</span>
                                                                                : log?.status === "2" ? <span>{log?.user_id ? ` ${log?.user_id}` : " ندارد "}</span>
                                                                                    : log?.status === "1" ? <span>{log?.user_id ? ` ${log?.user_id}` : " ... "}</span>
                                                                                        : log?.status === "0" ? <span>{log?.user_id ? ` ${log?.user_id}` : " ... "}</span>
                                                                                            : "..."
                                                                    }
                                                                </p>
                                                                <p className="m-0 text-sm mt-1 text-gray-600">
                                                                    نام :
                                                                    {
                                                                        log?.status === "3" ? <span>{log?.name ? ` ${log?.name}` : " ندارد "}</span>
                                                                            : log?.status === "4" ? <span>{log?.name ? ` ${log?.name}` : " ندارد "}</span>
                                                                                : log?.status === "2" ? <span> ندارد </span>
                                                                                    : log?.status === "1" ? <span>{log?.name ? ` ${log?.name}` : " ... "}</span>
                                                                                        : log?.status === "0" ? <span>{log?.name ? ` ${log?.name}` : " ... "}</span>
                                                                                            : "..."
                                                                    }
                                                                </p>
                                                                <p className="m-0 text-sm mt-1 text-gray-600">
                                                                    نوع اکانت: {log?.public === "1" && "Public"}
                                                                    {log?.public === "0" && "Private"}
                                                                    {log?.public == null && "ندارد"}
                                                                </p>
                                                            </div>
                                                            <div className="">
                                                                <p className="m-0 mt-1 text-sm text-gray-600">
                                                                    فالوئر:
                                                                    {
                                                                        log?.status === "3" ? <span>{log?.follower_count ? ` ${log?.follower_count}` : " ندارد "}</span>
                                                                            : log?.status === "4" ? <span>{log?.follower_count ? ` ${log?.follower_count}` : " ندارد "}</span>
                                                                                : log?.status === "2" ? <span> ندارد </span>
                                                                                    : log?.status === "1" ? <span>{log?.follower_count ? ` ${log?.follower_count}` : " ... "}</span>
                                                                                        : log?.status === "0" ? <span>{log?.follower_count ? ` ${log?.follower_count}` : " ... "}</span>
                                                                                            : "..."
                                                                    } | فالویینگ:
                                                                    {
                                                                        log?.status === "3" ? <span>{log?.following_count ? ` ${log?.following_count}` : " ندارد "}</span>
                                                                            : log?.status === "4" ? <span>{log?.following_count ? ` ${log?.following_count}` : " ندارد "}</span>

                                                                                : log?.status === "2" ? <span> ندارد </span>
                                                                                    : log?.status === "1" ? <span>{log?.following_count ? ` ${log?.following_count}` : " ... "}</span>
                                                                                        : log?.status === "0" ? <span>{log?.following_count ? ` ${log?.following_count}` : " ... "}</span> : "..."
                                                                    }
                                                                </p>
                                                                <p className="m-0 mt-1 text-sm text-gray-600 bio-holder-his">
                                                                    بیوگرافی:
                                                                    {log?.biography == null ? ' ندارد '
                                                                        : <span className=" cursor-pointer select-none" onClick={() => { handleOpenbio(); setFullBio(log?.biography) }}>{`${log?.biography?.length > 50
                                                                            ? `
                                        ...${log?.biography?.slice(0, 20)}`
                                                                            : log?.biography}`}</span>}
                                                                    {openBio &&
                                                                        <div className='bioWrap'>
                                                                            <div className='fullScreenImage'>
                                                                                <p className="text-center text-white">
                                                                                    <div className="flex justify-between border-b border-dark-blue">
                                                                                        <p className='flex justify-between text-lg text-white m-0'><span>بیوگرافی‌ کامل</span></p>
                                                                                        <span className=" text-white hover:opacity-70"><FontAwesomeIcon icon={faXmark} className=' cursor-pointer select-none' onClick={handleClosebio} /></span>
                                                                                    </div>
                                                                                    <p className=' overflow-y-auto text-white text-lg p-6 h-4/5'>{fullBio}</p>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </p>
                                                                <p className="m-0 mt-1 text-sm text-gray-600">
                                                                    تاریخ ثبت: {log?.created_at}
                                                                </p>
                                                                <p className="m-0 mt-1 text-sm text-gray-600">
                                                                    آخرین آپدیت: {log?.updated_at}
                                                                </p>
                                                                <p className={`m-0 mt-1 text-sm text-gray-600`}>
                                                                    وضعیت استعلام: <span className={`${(
                                                                        (log?.status === "0") ? 'text-gray-500' :
                                                                            (log?.status === "1") ? 'text-blue-500' :
                                                                                (log?.status === "2") ? 'text-red-500' :
                                                                                    (log?.status === "4") ? 'text-green-500' :
                                                                                        (log?.status === "3") ? 'text-green-500' : ""
                                                                    )
                                                                        }`}>{
                                                                            (log?.status === "0" ? 'در انتظار' :
                                                                                log?.status === "1" ? 'در حال استخراج اطلاعات' :
                                                                                    log?.status === "2" ? 'دارای شبکه اجتماعی نیست' :
                                                                                        log?.status === "4" ? 'کامل' :
                                                                                            log?.status === "3" ? 'کامل' : "ورودی نامشخص")
                                                                        }</span>
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    log?.username ? <div className="flex">
                                                        <div className="flex justify-between md:flex-col md:item items-center">
                                                            <div className="my-1 md:my-0 md: mx-1">
                                                                <a href={`https://www.instagram.com/${log?.username}/`} target="_blank">
                                                                    <button className={`py-1 px-2 w-32 hover:bg-yellow text-white text-base bg-dark-blue rounded select-none transition-all duration-200 ease-in`}>مشاهده اکانت</button>
                                                                </a>
                                                            </div>
                                                            {
                                                                log?.status !== "4" && <div className="my-1 md:my-0 md: mx-1">
                                                                    <NavLink to='/account-details' className='text-white text-base lg:text-lg'>
                                                                        <button className={`py-1 px-2 w-32 hover:bg-yellow text-white text-base bg-middle-green rounded select-none transition-all duration-200 ease-in`} onClick={() => { setShow(false); setUserName(log?.user_id) }}>فالوئر و فالویینگ</button>
                                                                    </NavLink>
                                                                </div>
                                                            }
                                                            {
                                                                log?.status === "3" && <div className="my-1 md:my-0 md: mx-1">
                                                                    <a href={log?.excel_export} download>
                                                                        <button className={`py-1 px-2 w-32 hover:bg-yellow text-white text-base bg-green-600 rounded select-none transition-all duration-200 ease-in`}>دانلود اکسل</button>
                                                                    </a>
                                                                </div>
                                                            }
                                                            {
                                                                log?.status === "1" &&
                                                                <div className="flex w-32 my-1 md:my-0 md: mx-1">
                                                                    <div className=" bg-slate-300 rounded w-28 h-2 my-1 md:my-0 md: mx-1">
                                                                        <div className="rounded h-2 bg-green-500" style={{ "width": `${(log?.page / 30) / log?.max_page * 100}%` }}>
                                                                        </div>
                                                                    </div>
                                                                    <div className="">{`${Math.ceil((log?.page / 30) / log?.max_page * 100)}%`}</div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div> : ""
                                                }

                                            </div>
                                            {/* <div className=' w-1/3 flex flex-col items-center justify-center'>
                                                <p className="m-0 text-sm text-right mt-1 text-gray-600">
                                                    تاریخ: {log?.date}
                                                </p>
                                                <p className="m-0 text-sm text-right mt-1 text-gray-600">
                                                    ساعت: {log?.time?.slice(0, 8)}
                                                </p>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    )

}

export default FollowHistory
