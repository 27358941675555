import { useState, useEffect } from "react";
import useAxiosPrivate from "./Hooks/useAxiosPrivate";
import ReactPaginate from 'react-paginate';

const SinUser = () => {
    const [sinUser, setSinUsers] = useState();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);
    // const navigate = useNavigate();
    // const location = useLocation();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                setLoading(true);
                const response = await axiosPrivate.get('/api/user/13', {
                    signal: controller.signal
                });
                // console.log(response.data.data);
                isMounted && setSinUsers(response?.data);
                console.log(response.data);
                console.log(sinUser);
                setLoading(false);
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        }

        getUsers();
        console.log(sinUser);
        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [])

    console.log(sinUser);

    return (

        <>
            {loading ? <div className='flex'>< p className=' text-right text-lg mt-2 pr-6' > در حال بارگذاری...</p ></div>
                : <div className="profile-container grid grid-cols-6">
                    <div className="col-span-4 col-start-2 content-center bg-purple-100 p-4 mt-4 rounded-md">
                        <p>نام: سپهر احمدی</p>
                        <p>نام کاربری: sepehrahmadi95@gmail.com</p>
                        <p>شماره تماس: 09107589830</p>
                        <p>وضعیت ناحیه کاربری: {sinUser?.status ? <span className="text-white text-sm bg-green-700 px-1 rounded">فعال</span> : <span className=" text-white text-sm bg-red-700 px-1 rounded">غیرفعال</span>}</p>
                        <p>نام کاربری: {sinUser?.Username}</p>
                        <p>شناسه کاربری: {sinUser?.id}</p>
                        <p>مدل دستگاه: {sinUser?.Model}</p>
                    </div>
                </div>}
        </>

    )
}

export default SinUser
