import { useContext, useState, useEffect } from "react";
import AuthContext from './Context/AuthProvider';
import Header from "./Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft, faXmark } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from 'react-paginate';
import axios from "./Api/axios";
import contactImg from '../img/contact.svg';

function InpHistory() {

    const { detShow } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [numList, setNumList] = useState();
    const [inpKind, setInpKind] = useState("id");
    const [fullBio, setFullBio] = useState();
    const [openBio, setOpenBio] = useState(false);

    const handleOpenbio = () => {
        setOpenBio(true);
    }

    // Close Modal
    const handleClosebio = () => {
        setOpenBio(false)
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getList = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`/list/${inpKind}`, {
                    signal: controller.signal
                });
                isMounted && setNumList(response?.data?.slice(0).reverse());
                setLoading(false);
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        }

        getList();
        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [inpKind])
    console.log(numList);

    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10;

    console.log(totalPages);

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const subset = numList?.slice(startIndex, endIndex);

    useEffect(() => {
        setTotalPages(Math.ceil(numList?.length / itemsPerPage));
    }, [subset]);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    const download = e => {
        console.log(e.target.href);
        fetch(e.target.href, {
            method: "GET",
            headers: {}
        })
            .then(response => {
                response.arrayBuffer().then(function (buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "image.jpeg"); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <>
            <Header />
            <section className="dark:bg-stone-900 dark:text-gray-100 transition-all duration-100 ease-in h-screen">
                <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 overflow-x-hidden'>
                    <div className="md:col-span-7 md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
                        <span className='inline-block place-content-center mr-4 text-green-800 dark:text-green-200 text-lg ml-2'><FontAwesomeIcon icon={faClockRotateLeft} /></span>
                        <h5 className="inline-flex items-center">تاریخچه استعلام</h5>
                        <div className="flex items-center px-8">
                            <p className="m-0">نوع استعلام را مشخص کنید:</p>
                            <ul className="m-0 flex">
                                {/* <li onClick={() => { setInpKind("all"); }} className={`p-2 hover:bg-yellow text-white text-sm mr-1  ${inpKind === "all" ? "bg-yellow cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`}><span>همه موارد </span>{inpKind == "all" && <span>({numList?.length})</span>}</li> */}
                                <li onClick={() => { setInpKind("id"); setCurrentPage(0) }} className={`p-2 hover:bg-yellow text-white text-sm mr-1  ${inpKind === "id" ? "bg-dark-blue cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`}><span>شناسه کاربری </span>{inpKind === "id" && <span>({numList?.length})</span>}</li>
                                <li onClick={() => { setInpKind("username"); setCurrentPage(0) }} className={`p-2 hover:bg-yellow text-white text-sm mr-1  ${inpKind === "username" ? "bg-dark-blue cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`}><span>نام کاربری </span>{inpKind === "username" && <span>({numList?.length})</span>}</li>
                                <li onClick={() => { setInpKind("mobile"); setCurrentPage(0) }} className={`p-2 hover:bg-yellow text-white text-sm mr-1  ${inpKind === "mobile" ? "bg-dark-blue cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`}><span>شماره موبایل </span>{inpKind === "mobile" && <span>({numList?.length})</span>}</li>
                            </ul>
                        </div>
                        <div className='pl-8 flex justify-end'>
                            {
                                !loading ? <div className='pr-6'>
                                    {numList?.length > 10 ? <ReactPaginate
                                        className='pagination-container flex items-center p-0 m-0 rounded-md w-fit'
                                        pageCount={totalPages}
                                        onPageChange={handlePageChange}
                                        forcePage={currentPage}
                                        previousLabel={"<<"}
                                        nextLabel={">>"}
                                        breakLabel={"..."}
                                        containerClassName={"pagination-container"}
                                        activeClassName={"active-page"}
                                    />
                                        : ''
                                    }
                                </div>
                                    : ''
                            }
                        </div>
                        <div className="news-card grid grid-cols-1 gap-x-4 mt-2 rounded-md px-2 inner-scroll-250 overflow-y-auto">
                            {loading ? <div className='flex'>< p className=' text-right text-lg mt-2 pr-6' > در حال بارگذاری...</p></div>
                                : subset?.map((log, i) => (
                                    // log.mobile &&
                                    <div key={i} className="w-full bg-gray-50 dark:bg-gray-600 hover:bg-blue-200 transition-all duration-200 ease-in border border-slate-400 rounded-md mb-3 p-3">
                                        <div className="news-card-header col-span-3">
                                            <div className='flex md:flex-col lg:flex-row justify-between'>
                                                <div className='flex justify-between items-center mr-6 w-full'>
                                                    <div className='flex flex-col justify-between'>
                                                        <div className="flex">
                                                            <div className="md:ml-4">
                                                                <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                    شماره تماس:
                                                                    {
                                                                        log?.mobile ? <span>{log?.mobile}</span>
                                                                            : log?.status === "2" ? <span> ندارد </span>
                                                                                : log?.status === "1" ? <span>...</span>
                                                                                    : "..."
                                                                    }
                                                                </p>
                                                                <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                    نام کاربری:
                                                                    {
                                                                        log?.username ? <span>{log?.username}</span>
                                                                            : log?.status === "2" ? <span> ندارد </span>
                                                                                : log?.status === "1" ? <span>...</span>
                                                                                    : "..."
                                                                    }
                                                                </p>

                                                                <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                    شناسه کاربری:
                                                                    {
                                                                        log?.user_id ? <span>{log?.user_id}</span>
                                                                            : log?.status === "2" ? <span> ندارد </span>
                                                                                : log?.status === "1" ? <span>...</span>
                                                                                    : "..."
                                                                    }
                                                                </p>
                                                                <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                    نام و نام خانوادگی‌:
                                                                    {
                                                                        log?.name ? <span>{log?.name}</span>
                                                                            // : log?.status === "3" ? <span> ندارد </span>
                                                                            : log?.status === "2" ? <span> ندارد </span>
                                                                                : log?.status === "1" ? <span>...</span>
                                                                                    : "..."
                                                                    }
                                                                </p>
                                                                <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                    نوع اکانت: {log?.public === "1" && "Public"}
                                                                    {log?.public === "0" && "Private"}
                                                                    {log?.public === null && "..."}
                                                                </p>
                                                            </div>
                                                            <div className="">
                                                                <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">
                                                                    فالوئر:
                                                                    {
                                                                        log?.follower_count ? <span>{log?.follower_count}</span>
                                                                            : log?.status === "2" ? <span> ندارد </span>
                                                                                : log?.status === "1" ? <span>...</span>
                                                                                    : "..."
                                                                    } | فالویینگ:
                                                                    {
                                                                        log?.following_count ? <span>{log?.following_count}</span>
                                                                            : log?.status === "2" ? <span> ندارد </span>
                                                                                : log?.status === "1" ? <span>...</span>
                                                                                    : "..."
                                                                    }
                                                                </p>
                                                                <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white bio-holder-his">
                                                                    بیوگرافی:
                                                                    {log?.biography === null ? ' ندارد '
                                                                        : <span className=" cursor-pointer select-none" onClick={() => { handleOpenbio(); setFullBio(log?.biography) }}>{`${log?.biography?.length > 50
                                                                            ? `
                                        ...${log?.biography?.slice(0, 20)}`
                                                                            : log?.biography}`}</span>}
                                                                    {openBio &&
                                                                        <div className='bioWrap'>
                                                                            <div className='fullScreenImage'>
                                                                                <p className="text-center text-white">
                                                                                    <div className="flex justify-between border-b border-dark-blue">
                                                                                        <p className='flex justify-between text-lg text-white m-0'><span>بیوگرافی‌ کامل</span></p>
                                                                                        <span className=" text-white hover:opacity-70"><FontAwesomeIcon icon={faXmark} className=' cursor-pointer select-none' onClick={handleClosebio} /></span>
                                                                                    </div>
                                                                                    <p className=' overflow-y-auto text-white text-lg p-6 h-4/5'>{fullBio}</p>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </p>
                                                                <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">تاریخ ثبت:
                                                                    {log?.created_at ? <span>{log?.created_at}</span> : " نامشخص "}
                                                                </p>
                                                                <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">
                                                                    آخرین آپدیت:
                                                                    {
                                                                        log?.updated_at ? log?.updated_at : " نامشخص "
                                                                    }
                                                                </p>
                                                                <p className={`m-0 mt-1 text-sm text-gray-600 dark:text-white`}>
                                                                    وضعیت استعلام: <span className={`${(
                                                                        (log?.status === "0") ? 'text-gray-500' :
                                                                            (log?.status === "1") ? 'text-blue-500' :
                                                                                (log?.status === "2") ? 'text-red-500' :
                                                                                    (log?.status === "3") ? 'text-green-500' : ""
                                                                    )
                                                                        }`}>{
                                                                            (log?.status === "0" ? 'در انتظار' :
                                                                                log?.status === "1" ? 'در حال استخراج اطلاعات' :
                                                                                    log?.status === "2" ? 'دارای شبکه اجتماعی نیست' :
                                                                                        log?.status === "3" ? 'کامل' : "ورودی نامشخص")
                                                                        }</span>
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        (log?.status === "3" || log?.status === "1") && <div className="flex flex-col items-center">
                                                            <div className="">
                                                                {
                                                                    log?.profile_image ? <a href={log?.profile_image} download target="_blank"><img src={log?.profile_image} alt="تصویر پروفایل" width={100} className="rounded-full" /></a>
                                                                        : <img src={contactImg} alt="تصویر پروفایل" width={100} className="rounded-full" />
                                                                }
                                                            </div>
                                                            {
                                                                log?.username && <div className="mt-2">
                                                                    <a href={`https://www.instagram.com/${log?.username}/`} target="_blank">
                                                                        <button className={`py-1 px-2 hover:bg-yellow text-white text-base bg-green-500 rounded select-none transition-all duration-200 ease-in`}>مشاهده اکانت</button>
                                                                    </a>
                                                                </div>
                                                            }
                                                        </div>
                                                    }

                                                </div>
                                                {/* <div className=' w-1/3 flex flex-col items-center justify-center'>
                                                <p className="m-0 text-sm text-right mt-1 text-gray-600 dark:text-white">
                                                    تاریخ: {log?.date}
                                                </p>
                                                <p className="m-0 text-sm text-right mt-1 text-gray-600 dark:text-white">
                                                    ساعت: {log?.time?.slice(0, 8)}
                                                </p>
                                            </div> */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

export default InpHistory
