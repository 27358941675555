import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import contactImage from '../img/contact.svg';
import Header from './Header';
import data from '../JsonFiles/Contacts.json';
import { faContactBook } from '@fortawesome/free-solid-svg-icons';
function Contacts() {

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 12;

  useEffect(() => {
    setTotalPages(Math.ceil(data.length / itemsPerPage));
  }, []);

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const subset = data.slice(startIndex, endIndex);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  return (

    <>
      <Header />
      <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 overflow-y-auto'>
                <div className="md:col-span-7 bg-white md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
                <span className='inline-block  place-content-center mr-4 text-blue-800 text-lg ml-2'><FontAwesomeIcon icon={faContactBook} /></span>
                <h5 className="inline-flex items-center">مخاطبین</h5>
                <p className=' px-8 text-sm md:text-base'>
            لیست مخاطبین در این بخش قابل مشاهده است.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 rounded-md px-4 pb-4">
            {subset.map((log, i) => (

              <div key={i} className="grid grid-cols-6 gap-1 items-center justify-start w-full rounded-md bg-gray-100 mb-1 p-2">
                <img src={contactImage} className="rounded-full" width={40} height={40} />
                <div className="col-span-3">
                  <h6 className="text-sm mb-1 pt-1">{log.name} + {currentPage}
                  </h6>
                  <p className="m-0 text-xs">
                    {log.number}
                  </p>
                </div>
                <div className="col-span-2 flex flex-col justify-center items-center">
                  <button className="text-xs bg-blue-400 hover:bg-middle-yellow rounded-md w-fit mb-1 p-2"><Link className="text-bk" to="/call-log">تماس‌ها</Link></button>
                  <button className="text-xs bg-green-400 hover:bg-middle-yellow  rounded-md w-fit p-2"><Link className="text-bk" to="/chatbox">پیامک‌ها</Link></button>
                </div>
              </div>
            ))}
          </div>

          <div className='pr-6'>
            <ReactPaginate
              className='pagination-container flex items-center p-0 m-0 rounded-md w-fit'
              pageCount={totalPages}
              onPageChange={handlePageChange}
              forcePage={currentPage}
              previousLabel={"<<"}
              nextLabel={">>"}
              breakLabel={"..."}
              containerClassName={"pagination-container"}
              activeClassName={"active-page"}
            />
          </div>
        </div>
      </div>



    </>
  )
}

export default Contacts
