import React from 'react';
import { Routes, Route } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css"
import '../CSS/index.css';
import Home from './Home';
import Layout from './Layout';
import LoginPage from './SignIn';
import AccInq from './AccInq';
import NumInq from './NumInq';
import NumSubmit from './NumSubmit';
import Register from './SignUp';
import Contacts from './Contacts';
import Missing from './Missing';
import RequireAuth from './RequireAuth';
import RequireNotAuth from './RequireNotAuth';
import Gallery from './Gallery';
import Profile from './Profile';
import ActionList from './ActionList';
import AccDet from './AccDet';
import InpHistory from './InpHistory';
import TwDet from './TwDet';
import TelDet from './TelDet';
import InstaPost from './InstaPost';
import FollowHistory from './FollowHistory';
import ChaBox from './ChatBox'
import SignInGpt from './SignInGpt'

function App() {
  return (

    <Routes>
      <Route path='/' element={<Layout />}>
        <Route element={<RequireNotAuth />}>
          <Route path='/sign-in' element={<LoginPage />} />
          <Route path='/sign-up' element={<Register />} />
        </Route>
        <Route element={<RequireAuth />}>
          <Route path='/' element={<Home />} />
          <Route path='/social-inquiry' element={<AccInq />} />
          <Route path='/number-inquiry' element={<NumInq />} />
          <Route path='/number-submit' element={<NumSubmit />} />
          <Route path='/contacts' element={<Contacts />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/actions' element={<ActionList />} />
          <Route path='/account-details' element={<AccDet />} />
          <Route path='/input-history' element={<InpHistory />} />
          <Route path='/telegram-details' element={<TelDet />} />
          <Route path='/twitter-details' element={<TwDet />} />
          <Route path='/instagram-posts' element={<InstaPost />} />
          <Route path='/instagram-history' element={<FollowHistory />} />
          <Route path='/chatgpt' element={<ChaBox />} />
          <Route path='/signin-gpt' element={<SignInGpt />} />
        </Route>
        <Route path='*' element={<Missing />} />
      </Route>
    </Routes>


  );
}

export default App;
